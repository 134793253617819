import classes from "./Careers.module.scss";
import EmbracingDiversityAndInnovation from "./EmbracingDiversityAndInnovation/EmbracingDiversityAndInnovation";

function Careers() {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img
          className={classes["header-img"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/careers.png"
          alt="Careers Us"
        />

        <div className={classes.title}>Careers</div>
      </div>

      <EmbracingDiversityAndInnovation />
    </div>
  );
}

export default Careers;
