import React from 'react'
import classes from './ClemEcosystem.module.scss';
import { SolutionsData } from '../../utils/data/sectorWiseSolutions';

const ClemEcosystem = () => {
    return (
        <div className={classes['clem-ecosystem']}>
            <div className={classes["ecosystem-title"]}>
                CLEM Ecosystem
            </div>
            <div className={classes['separator']}></div>
            <img className={classes["img-container"]} src={SolutionsData?.CLEMEcosystem?.imgSrc}/>
        </div>
    )
}

export default ClemEcosystem