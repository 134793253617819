import styles from "./TermsAndConditions.module.scss";

function TermsAndConditions() {
  return (
    <article className={styles.container}>
      <h1 className={styles.heading}>Terms and Conditions</h1>

      <ol className={styles.mainList}>
        <li>
          <strong>Introduction</strong>
          <p>
            This is to inform all our visitors and users that all the
            information and contents of our site is copyrighted and our site is
            for sole purpose of your personal use.
          </p>
        </li>

        <li>
          <strong>Acceptance of Terms</strong>
          <p>
            By accessing and using this website, you agree to be bound by these
            terms and conditions. That by accessing or using our site or any
            other portal you agree to the said terms and conditions and are
            legally obliged to adhere to the same.
          </p>
        </li>

        <li>
          <strong>Use of Content</strong>
          <p>
            All content on this website is for informational purposes only. You
            may not modify, reproduce, or distribute any material without prior
            written consent.
          </p>
        </li>

        <li>
          <strong>Copyright and Intellectual Property</strong>
          <ol>
            <li>
              All the information and content shared on our portal is
              copyrighted and is the sole property of Sustainability Economics
              Pte. Ltd. Any claims or publishing of any part or constituent of
              our articles, blogs, data analysis shall amount to breach and
              invite liability.
            </li>
            <li>
              All content, including text, images, graphics, trademarks, logos
              and multimedia on this website is the intellectual property of
              Sustainability Economics Pte. Ltd. Unauthorised use or
              reproduction is strictly prohibited without proper written
              consent.
            </li>
            <li>
              We reserve the right to change our copyright policy from time to
              time by publishing an updated policy on our Website, which shall
              become effective and replace any previous policy with effect from
              publication.
            </li>
            <li>
              Breach or unauthorised use of any intellectual property amounts to
              breach of the said terms and conditions and amounts to liability
              under the prescribed law.
            </li>
          </ol>
        </li>

        <li>
          <strong>Usage and Restrictions on use</strong>
          <ol>
            <li>
              All the information and content displayed on our website is
              strictly for your personal use only. The information can be
              accessed on the website by registering and creating a login as
              user.. The sole purpose of the site is to provide latest news,
              updates and information to our globally registered customers.
              Users must not engage in any activity that disrupts the website,
              violates laws, or infringes on the rights of others.
            </li>
            <li>
              The users can print the content published on our website only for
              their personal use and it cannot be used for publishing or
              advertising any other commercial activity. All our users and
              subscribers are required not to use or publish the feed as part of
              a paid for service or for other commercial gain.
            </li>
            <li>
              By accessing our site, you affirm that any resale, transfer or
              benefit arising out of your usage shall in any manner not
              constitute any profitable activity.
            </li>
            <li>
              Information and data published on our site can be shared by all
              our users via shared links. However, our users are prohibited for
              any kind of plagiarism for third party sharing.
            </li>
            <li>
              We only allow third party advertising relating to our portal with
              prior permission.
            </li>
            <li>
              Our services or portal cannot be used for any illegal or illicit
              purposes. Any usage which is in violation of any law/regulation or
              guidelines is strictly prohibited.
            </li>
            <li>
              All information received by us from your use of the website will
              be used in accordance with our Privacy Policy. Our privacy policy
              outlines how we collect, use, and protect your personal
              information. By using this website, you consent to our privacy
              practices.
            </li>
            <li>
              Copy, publish or redistribute full text articles, photographs,
              graphics, tables or images in any way (except as permitted by any
              sharing tools we make available) is strictly prohibited.
            </li>
            <li>
              Create derivative works from our content, unless you are creating
              summaries as described above is not permitted.
            </li>
          </ol>
        </li>

        <li>
          <strong>Termination</strong>
          <ol>
            <li>
              We reserve the right to terminate or suspend access to the website
              for any violation of these terms.
            </li>
            <li>
              You can use the unsubscribe link in your user account in case you
              want with our services or no longer need the said subscription.
              Please ensure that unsubscribing our page will only terminate your
              user subscription with us, yet you will be bound to all the other
              terms and conditions while accessing our website or any other
              electronic portal.
            </li>
          </ol>
        </li>

        <li>
          <strong>Governing Law and Jurisdiction</strong>
          <ol>
            <li>
              These terms and conditions are governed by laws of Singapore and
              the parties accessing the Website agree/submit/concede to the
              exclusive jurisdiction of the Courts in Singapore. In case of any
              dispute kindly reach out to us at{" "}
              <a
                href="mailto:contact@sustainabilityeconomics.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i>contact@sustainabilityeconomics.com</i>
              </a>
            </li>
            <li>
              We expect our users to resolve any issue or dispute amicably.
              However, if in any case we fail to resolve the said dispute
              amicably within a span of 30 days, then such disputes shall be
              subjected to Arbitration.
            </li>
            <li>
              All disputes and differences by and between the Parties hereto, in
              any way relating to or connected with Our Website or portal and/or
              anything done in pursuance hereof shall be referred to
              arbitration. The Lessor and Lessee shall jointly appoint a sole
              arbitrator.
            </li>
            <li>
              The arbitration shall be conducted in accordance with the SIAC
              Rules of Arbitration or its statutory modifications amendments or
              re-enactment thereof. The award of the arbitrator shall be final
              and binding upon the Parties.
            </li>
            <li>
              The venue and seat of the arbitration shall be at Singapore. The
              language of the arbitration shall be in English. The arbitration
              proceedings and all other matters connected to the arbitration
              hereunder shall be subject to the exclusive jurisdiction of Courts
              at Singapore.
            </li>
          </ol>
        </li>

        <li>
          <strong>Waiver</strong>
          <p>
            It is pertinent to note that any Inability our end to enforce any
            clause of this T and C shall not be construed as a waiver of any
            provision or our rights.
          </p>
        </li>

        <li>
          <strong>Severability</strong>
          <p>
            If any of the above covenants or conditions is found to be invalid
            or cannot be enforced under the adjudicating law, in such case it
            shall not affect the enforceability of the rest of the T & Cs.
          </p>
        </li>

        <li>
          <strong>Entire Agreement</strong>
          <p>
            All the terms and conditions of our agreement with all our users are
            not limited to these T and Cs but also includes Privacy Policy and
            all the inherent rights which exist by default over our intellectual
            property.
          </p>
        </li>

        <li>
          <strong>Miscellaneous</strong>
          <ol>
            <li>
              Please note that we may change these terms and conditions from
              time to time at our sole discretion and we reserve the right to do
              so without your consent. All our users are requested to kindly
              keep a check on the portal for the same.
            </li>
            <li>
              Kindly note that links to all other sites/advertisements or any
              other pop-ups/cookies of other websites or applications published
              on our portal does not ensure privacy and terms which are
              guaranteed by us.
            </li>
            <li>
              We recommend you to go through the Privacy Policy in order to know
              the whereabouts of how your data and information is protected with
              us.
            </li>
            <li>
              We are not liable for any direct, indirect, or consequential
              damages arising from the use of this website.
            </li>
          </ol>
        </li>
      </ol>
    </article>
  );
}

export default TermsAndConditions;
