import { useState } from "react";
import Card from "../Card/Card";
import classes from "./SeBackgroungHoverCardsMobile.module.scss";
import { ReactComponent as TopWave } from "../../assets/icons/top_wave.svg";
import { ReactComponent as DownWave } from "../../assets/icons/down_wave.svg";

type Props = {
  title: string;
  subTitle: string;
  cards: { title: string; desc: string }[];
};

const SeBackgroundHoverCardsMobile = (props: Props) => {
  const { title, subTitle, cards } = props;
  const [hoveredIndex, setHoveredIndex] = useState<number>(0);

  const mouseEnterHandler = (receivedIndex: number) => () => {
    setHoveredIndex(receivedIndex);
  };

  return (
    <div className={classes["cater-to-container"]}>
      <div className={classes["ctc-title"]}>{title}</div>
      <div className={classes["ctc-separator"]} />
      <div className={classes["ctc-cards-container"]}>
        {cards.map((el, index: number) => (
          <Card
            key={index}
            customClass={
              hoveredIndex === index
                ? `${classes["ctc-card"]} ${classes["ctc-card-expanded-mobile"]}`
                : `${classes["ctc-card"]} ${classes["ctc-card-normal-mobile"]}`
            }
            extraProps={{
              onMouseEnter: mouseEnterHandler(index),
              onClick: mouseEnterHandler(index),
            }}
          >
            <div className={classes["title-separator-container-popup"]}>
              <div className={classes["ctc-card-title"]}>{el.title}</div>
            </div>
            <div className={classes["each-card"]}>
              <span className={classes["white-space"]} />
              <span className={classes["ctc-card-desc"]}>
                {hoveredIndex === index && <p>{el.desc}</p>}
              </span>
            </div>
          </Card>
        ))}
      </div>
      <div className={classes["top-wave-container"]}>
        <TopWave />
      </div>
      <div className={classes["bottom-wave-container"]}>
        <DownWave />
      </div>
    </div>
  );
};

export default SeBackgroundHoverCardsMobile;
