import classes from "./Events.module.scss";
import { ReactComponent as Arrow } from "../../assets/icons/arrow_with_tail.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const upcomingEvents: any[] = [];

const pastEvents = [
  {
    date: "23rd - 26th  Sept’24",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/CWNYC+2022+-+OC+2.jpg",
    title: "Climate Week NYC 2024!",
    desc: "CLEM (Clean Energy Mechanism)- Making Clean Energy Transitions Profitable",
    link: "/resources/events",
  },
  {
    date: "26th - 28th  Jun’23",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/greenfin-summit.jpg",
    title: "GreenFin Boston Summit",
    desc: "We showcased our comprehensive end-to-end transition solutions designed.",
    link: "/resources/events/greenfin-summit",
  },
];

const Events = () => {
  const navigate = useNavigate();

  const navLinkClickHandler = (link: string) => () => {
    navigate(link);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Set the tab name
    document.title =
      "Upcoming Events | Accelerate Your Net-Zero Transition Journey";

    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Join Sustainability Economics at our events to explore strategies and technologies driving net-zero transitions. Network with industry leaders and experts."
      );
    } else {
      // If the meta tag doesn't exist, create it
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Join Sustainability Economics at our events to explore strategies and technologies driving net-zero transitions. Network with industry leaders and experts.";
      document.head.appendChild(meta);
    }

    // Clean up (if necessary) when component unmounts
    return () => {
      document.title = "Default Title"; // Restore the default title
    };
  }, []);

  return (
    <div className={classes["events-page-container"]}>
      <div className={classes.header}>
        <img
          className={classes["header-img"]}
          src="https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/IMG_0057-scaled+2.jpg"
          alt="Events"
        />

        <div className={classes.title}>Events</div>
      </div>

      {upcomingEvents && upcomingEvents.length > 0 && (
        <div className={classes["events-parent-container"]}>
          <div className={classes["events-upcoming-events"]}>
            Upcoming events
          </div>
          <div className={classes["separator"]}></div>
          <div className={classes["events-container"]}>
            {upcomingEvents.map((event, index) => (
              <div
                key={index}
                className={classes["event-ele-separator-container"]}
                onClick={navLinkClickHandler("/events")}
              >
                <div className={classes["event-ele-container"]}>
                  <div className={classes["event-date"]}>{event.date}</div>
                  <div className={classes["event-img"]}>
                    <img
                      src={event.img}
                      height="100%"
                      width="100%"
                      alt="steppe.png"
                      loading="lazy"
                    ></img>
                  </div>
                  <div className={classes["event-info"]}>
                    <div className={classes["event-info-date"]}>
                      {event.date}
                    </div>

                    <div>
                      <p className={classes["event-title"]}>{event.title}</p>
                      <p className={classes["event-desc"]}>{event.desc}</p>
                    </div>
                    <div className={classes["event-link-container"]}>
                      <Arrow />
                    </div>
                  </div>
                </div>
                <div className={classes["separator"]}></div>
              </div>
            ))}
          </div>
        </div>
      )}

      {pastEvents && pastEvents.length > 0 && (
        <div className={classes["events-parent-container"]}>
          <div className={classes["events-upcoming-events"]}>Past Events</div>
          <div className={classes["separator"]}></div>
          <div className={classes["events-container"]}>
            {pastEvents.map((event, index) => (
              <div
                key={index}
                className={classes["event-ele-separator-container"]}
                onClick={navLinkClickHandler(event.link)}
              >
                <div className={classes["event-ele-container"]}>
                  <div className={classes["event-date"]}>{event.date}</div>
                  <div className={classes["event-img"]}>
                    <img
                      src={event.img}
                      height="100%"
                      width="100%"
                      alt="steppe.png"
                      loading="lazy"
                    ></img>
                  </div>
                  <div className={classes["event-info"]}>
                    <div className={classes["event-info-date"]}>
                      {event.date}
                    </div>

                    <div>
                      <p className={classes["event-title"]}>{event.title}</p>
                      <p className={classes["event-desc"]}>{event.desc}</p>
                    </div>
                    <div className={classes["event-link-container"]}>
                      <Arrow />
                    </div>
                  </div>
                </div>
                <div className={classes["separator"]}></div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Events;
