import classes from "./OurStrategyCards.module.scss";
import Card from "../../../../Components/Card/Card";
import { useState } from "react";
import {
  mobileStrategyArr,
  strategyArr,
} from "../../../utils/data/strategyArr";
import useIsMobile from "../../../../Hooks/useIsMobile";

const OurStrategyCards = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number>(0);

  const isSmMobile = useIsMobile(480);

  const mouseEnterHandler = (receivedIndex: number) => () => {
    setHoveredIndex(receivedIndex);
  };

  const mouseLeaveHandler = () => {
    setHoveredIndex(-1);
  };

  return (
    <div className={classes["os-cards-container"]}>
      {(isSmMobile ? mobileStrategyArr : strategyArr).map((el, index) => (
        <Card
          key={index}
          customClass={
            hoveredIndex === index
              ? `${classes["os-card"]}`
              : `${classes["os-card"]}`
          }
          extraProps={{
            onMouseEnter: mouseEnterHandler(index),
            onMouseLeave: mouseLeaveHandler,
            onClick: mouseEnterHandler(index),
          }}
        >
          <div className={classes["card-title"]}>
            <p
              className={
                hoveredIndex === index
                  ? ` ${classes["os-card-expanded"]}`
                  : ` ${classes["os-card-normal"]}`
              }
            >
              {el.title}
            </p>
          </div>
          <div className={classes["card-content"]}>
            <div className={classes["white-space"]}></div>
            <div className={classes["card-desc"]}>
              {hoveredIndex === index && <p>{el.desc}</p>}
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default OurStrategyCards;
