const clemKeyOutcomes = [
    {
      title: "Current state discovery",
      description:
        "Data-driven extraction of technical and financials of current state of CFPP.",
    },
    {
      title: "Transition planning",
      description:
        "Practical location/country-specific transition plan (phased approach) by collaborating with CFPP owners, power purchasers, financial institutions, and policymakers.",
    },
    {
      title: "Economic gap",
      description:
        "Practical data-driven mechanism to nail down exact dollars CFPP owners have to forego as part of the transition.",
    },
    {
      title: "Energy mix",
      description:
        "Model-driven approaches covering region/country-specific policies to identify the potential clean energy source technologies and the cost associated along with financial analysis. Build clean energy source technologies working with region/country specific partners.",
    },
    {
      title: "Transition finance",
      description:
        "Data-driven, practical approach to identify transition financing requirements mapping with clear timelines covering different business models. Constructing transition financing requirements by using innovative global financial instruments to make transition profitable and working with our equity, debt global financial partners to raise funds.",
    },
    {
      title: "Transition Credits",
      description:
        "As an end-to-end player, methodology developer, and project proponent, we serve as a one-stop shop for managing the entire lifecycle of transition credits from concept to cashout.",
    },
    {
      title: "Blockchain-driven Marketplace",
      description:
        "Source the right clean energy source technology by working with clean energy source developers to speed up the transition.",
    },
    {
      title: "Financial analysis",
      description:
        "Provide detailed financial analysis with innovative transition financing instruments and transition credit cash flows for both equity and debt players.",
    },
    {
      title: "Summary",
      description:
        "Demonstrate profitability with transitions by comparing different options for CFPP owners and financial institutions.",
    },
  ];

export default clemKeyOutcomes;