export interface StrategyItem {
  title: string;
  desc: string;
}

export const strategyArr: StrategyItem[] = [
  {
    title: "Transition Finance",
    desc: "We streamline the process of transition financing by making projects bankable through strategic partnerships with the right stakeholders, including equity and debt partners. We ensure a profitable transition by using the most suitable and low-cost financing instruments.",
  },
  {
    title: "Low-Carbon Business Developer",
    desc: "We provide standard model driven approach to identify the right combination of renewable energy. It provides customised extensions to the standard models to account for policy driven constraints. By partnering with the right stakeholders, we ensure the development of renewable energy plants at optimal costs.",
  },
  {
    title: "Blockchain-Driven Marketplace",
    desc: "Our blockchain-driven marketplace provides a platform where readily available renewable energy plants and CFPP buyouts are listed, facilitating faster transitions from CFPPs. This marketplace also enables financial institutions to more effectively decarbonize their portfolios by offering streamlined access to clean energy assets.",
  },
  {
    title: "Transition Credits",
    desc: "As an end-to-end player, methodology developer, and project proponent, we serve as a one-stop shop for managing the entire lifecycle of transition credits. From defining the credits to securing cash flows, we ensure that the process is profitable for all involved parties.",
  },
];

export const mobileStrategyArr: StrategyItem[] = [
  {
    title: "Transition Finance",
    desc: "There are too many financing instruments and frameworks, which creates confusion and inefficiency. CLEM streamlines this process by making projects bankable through strategic partnerships with the right stakeholders, including equity and debt partners. CLEM ensures a profitable transition by using the most suitable, low-cost financing instruments.",
  },
  {
    title: "Low Carbon Business Developer",
    desc: "There is a lack of understanding regarding the capacity, land requirements, and costs associated with the right combination of clean energy sources (solar, wind, battery). CLEM provides standard model driven approach to identify the right combination of renewable energy. It provides customised extensions to the standard models to account for policy driven constraints. By partnering with the right stakeholders, CLEM ensures the development of renewable energy plants at optimal costs.",
  },
  {
    title: "Blockchain driven Marketplace",
    desc: "There is a lack of marketplace for purchasing readily available renewable energy capacity or coal-fired power plant (CFPP) buyouts, hindering the acceleration of transitions from coal to clean energy sources. Our blockchain-driven marketplace provides a platform where readily available renewable energy plants and CFPP buyouts are listed, facilitating faster transitions from CFPPs.",
  },
  {
    title: "Transition Credits",
    desc: "There is a lack of clarity around the end-to-end process of transition credits, from their initial concept to cash out, making it difficult for stakeholders to fully understand and capitalize on these financial instruments. As an end-to-end player, methodology developer, and project proponent, we serve as a one-stop shop for managing the entire lifecycle of transition credits.",
  },
];
