import classes from "./OurStrategy.module.scss";
import EmptyArrow from "../../../assets/icons/EmptyArrow";
import { useState } from "react";
import useIsMobile from "../../../Hooks/useIsMobile";
import OurStrategyMobile from "../../OurStrategyMobile/OurStrategyMobile";
import strategyArr from "./strategyContent";
import HoverSvg from "./HoverSvg/HoverSvg";

const OurStrategy = () => {
  const isMobile = useIsMobile(769);
  const [dynamicStrategyArr, setDynamicStrategyArr] = useState(strategyArr);
  const [activeBoxNumber, setActiveBoxNumber] = useState(1);
  const [position, setPosition] = useState(0);

  let dotArr = [0, 175, 350, 525, 700, 875, 1050];

  const leftClickHandler = () => {
    setActiveBoxNumber((prevState) => {
      if (prevState - 1 < 1) {
        return strategyArr.length;
      } else {
        return prevState - 1;
      }
    });
    setPosition((prevState) => prevState - 210);
  };
  const rightClickHandler = () => {
    setActiveBoxNumber((prevState) => {
      if (prevState + 1 > strategyArr.length) {
        return 1;
      } else {
        return prevState + 1;
      }
    });

    setPosition((prevState) => prevState + 210);
  };
  if (isMobile) {
    return <OurStrategyMobile />;
  }
  return (
    <div className={classes["strategy-container"]}>
      <div className={classes["strategy-title"]}>Our Strategy</div>
      <div className={classes["separator"]} />
      <div className={classes["se-strategy-img-container"]}>
        <HoverSvg />
        {/* <img
          alt="seStrategy.png"
          src={strategy}
          className={classes["strategy-img"]}
        /> */}
      </div>
      {/* <div className={classes["dots-line-container"]}>
        <div className={classes["line"]}></div>
        <div className={classes["dots-container"]}>
          {dotArr.map((el, index) => (
            <div
              className={
                index === 3
                  ? `${classes["dot"]} ${classes["dot-expanded"]}`
                  : classes["dot"]
              }
              //   style={{
              //     transform: `translateX(${position}px)`,
              //   }}
            >
              {index === 3 && `0${activeBoxNumber}`}
            </div>
          ))}
        </div>
        <div className={classes["arrow-card-container"]}>
          <div className={classes["arrow-left-container"]}>
            <div onClick={leftClickHandler}>
              <EmptyArrow />
            </div>
          </div>
          <div className={classes["card-container"]}>
            <div className={classes["card-body"]}>
              <p className={classes["card-title"]}>
                {strategyArr[activeBoxNumber - 1].title}
              </p>
              <p className={classes["card-desc"]}>
                {strategyArr[activeBoxNumber - 1].desc}
              </p>
            </div>
          </div>
          <div className={classes["arrow-right-container"]}>
            <div onClick={rightClickHandler}>
              <EmptyArrow style={{ transform: "rotate(180deg)" }} />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default OurStrategy;
