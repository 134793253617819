import React, { ReactNode } from "react";
import classes from "./Card.module.scss";

interface CardProps {
  onClick?: () => void;
  children: ReactNode;
  style?: React.CSSProperties;
  customClass?: string;
  extraProps?: any;
}

const Card = ({
  children,
  style = {},
  customClass = "",
  extraProps = {},
  onClick,
}: CardProps) => {
  return (
    <div
      className={`${classes["card-container"]} ${customClass}`}
      style={style}
      onClick={onClick}
      {...extraProps}
    >
      {children}
    </div>
  );
};

export default Card;
