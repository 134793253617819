import { useState, useRef, useEffect } from "react";
import classes from "./HoverSvg.module.scss";
import strategyArr from "../strategyContent";
import InfiniteModules from "../InfiniteModules/InfiniteModules";
import PauseIcon from "../../../../assets/icons/playAndPauseIcon/PauseIcon";
import PlayIcon from "../../../../assets/icons/playAndPauseIcon/PlayIcon";

const HoverSvg = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const elementsRef = useRef<any[]>([]);

  const [popup, setPopup] = useState({
    visible: false,
    title: "",
    desc: "",
    x: 0,
    y: 0,
  });

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [paused, setPaused] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  
  const AUTO_HIGHLIGHT_DELAY = 2000; 
  const NEXT_INDEX_DELAY = 700;
  const ANIMATION_START_DELAY = 1500; 
  const VISIBILITY_THRESHOLD = 0.85;  

  const autoHighlightRef = useRef<NodeJS.Timeout | null>(null);
  const animationStartTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = (event: any, index: number) => {
    if (autoHighlightRef.current) {
      clearTimeout(autoHighlightRef.current);
    }

    const path = event.currentTarget;
    const rect = path.getBoundingClientRect();
    const parentRect = containerRef.current?.getBoundingClientRect() || {
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    };

    const x = rect.left - parentRect.left;
    const y = rect.top - parentRect.top;

    const { title, desc } = strategyArr[index];

    setPopup({ visible: true, title, desc, x, y });
    setCurrentIndex(index);
    setPaused(true);
  };

  const handlePopupMouseLeave = () => {
    setPopup({ ...popup, visible: false });
    setPaused(false);
  };

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  useEffect(() => {
    if (isPlaying && !paused && isVisible) {
      if (currentIndex === 0) {
        const element = elementsRef.current[0];
        if (element) {
          const rect = element.getBoundingClientRect();
          const parentRect = containerRef.current?.getBoundingClientRect() || {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
          };

          const x = rect.left - parentRect.left;
          const y = rect.top - parentRect.top;

          const { title, desc } = strategyArr[0];
          setPopup({ visible: true, title, desc, x, y });
        }
      }

      autoHighlightRef.current = setTimeout(() => {
        setPopup((prev) => ({ ...prev, visible: false }));

        setTimeout(() => {
          const nextIndex = (currentIndex + 1) % strategyArr.length;
          const element = elementsRef.current[nextIndex];

          if (element) {
            const rect = element.getBoundingClientRect();
            const parentRect =
              containerRef.current?.getBoundingClientRect() || {
                left: 0,
                top: 0,
                width: 0,
                height: 0,
              };

            const x = rect.left - parentRect.left;
            const y = rect.top - parentRect.top;

            const { title, desc } = strategyArr[nextIndex];
            setPopup({ visible: true, title, desc, x, y });
            setCurrentIndex(nextIndex);
          }
        }, NEXT_INDEX_DELAY); 
      }, AUTO_HIGHLIGHT_DELAY);
    }

    return () => {
      if (autoHighlightRef.current) {
        clearTimeout(autoHighlightRef.current);
      }
    };
  }, [currentIndex, paused, isVisible, isPlaying]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.intersectionRatio >= VISIBILITY_THRESHOLD) {
          animationStartTimeoutRef.current = setTimeout(() => {
            setIsVisible(true);
          }, ANIMATION_START_DELAY);
        } else {
          setIsVisible(false);
          if (animationStartTimeoutRef.current) {
            clearTimeout(animationStartTimeoutRef.current);
          }
        }
      },
      { threshold: VISIBILITY_THRESHOLD }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      if (animationStartTimeoutRef.current) {
        clearTimeout(animationStartTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;

      const isOutsidePopup =
        popupRef.current && !popupRef.current.contains(target);
      const isOutsideRefs = elementsRef.current.every(
        (ref) => ref && !ref.contains(target)
      );

      if (isOutsidePopup && isOutsideRefs) {
        setPopup((prev) => ({ ...prev, visible: false }));
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (popup.visible && popupRef.current) {
      const popupRect = popupRef.current.getBoundingClientRect();
      const parentRect = containerRef.current?.getBoundingClientRect() || {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      };

      const adjustedX = Math.min(popup.x, parentRect.width - popupRect.width);
      const adjustedY = Math.min(popup.y, parentRect.height - popupRect.height);

      setPopup((prev) => ({
        ...prev,
        x: adjustedX,
        y: adjustedY,
      }));
    }
  }, [popup.visible, popup.x, popup.y]);

  return (
    <div className={classes.container} ref={containerRef}>
      <InfiniteModules
        elementsRef={elementsRef}
        popup={popup}
        handleMouseEnter={handleMouseEnter}
      />
      <div
        ref={popupRef}
        style={{
          position: "absolute",
          top: popup.y - 2,
          left: popup.x - 2,
          zIndex: 1,
          visibility: popup.visible ? "visible" : "hidden",
        }}
        onMouseLeave={handlePopupMouseLeave}
        className={`${classes.popup} ${
          popup.visible ? classes.visible : classes.hidden
        }`}
      >
        <div className={classes.title}>{popup.title}</div>
        <div className={classes.desc}>{popup.desc}</div>
      </div>
      {/* <button
        onClick={togglePlayPause}
        style={{ background: "none", border: "none" }}
        className={classes.playPauseButton}
      >
        {isPlaying ? (
          <PauseIcon/>
        ) : (
         <PlayIcon/>
        )}
      </button> */}
    </div>
  );
};

export default HoverSvg;
