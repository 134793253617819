import classes from "./VideoSection.module.scss";
import { useRef, useEffect } from "react";

const VideoSection = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videoElement.play();
          } else {
            videoElement.pause();
          }
        });
      },
      {
        threshold: 0.5, // Adjust this to define how much of the video needs to be visible to play
      }
    );

    observer.observe(videoElement);

    return () => {
      observer.disconnect(); // Cleanup the observer on component unmount
    };
  }, []);

  return (
    <div className={classes["video-section-container"]}>
      <div className={classes["video-container"]}>
        <video
          src={
            "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/overview+video.mp4"
          }
          autoPlay
          loop
          muted
          controls={true} /* If you want to hide controls */
          width="100%"
          height="100%"
          ref={videoRef}
        ></video>
      </div>
    </div>
  );
};

export default VideoSection;
