import classes from "./EventInfo.module.scss";
import { useMemo } from "react";
import TabComponent from "../../../../Components/TabbedComponent/TabbedComponent";
import MainEvent from "./MainEvent/MainEvent";
import BreakoutSession from "./BreakoutSession/BreakoutSession";

const EventInfo = ({
  eventsRef,
  isMobile,
  mainEventActive,
  setMainEventActive,
  showContent,
  setShowContent,
}: {
  eventsRef: React.RefObject<HTMLDivElement>;
  isMobile: any;
  mainEventActive: boolean;
  setMainEventActive: Function;
  showContent: boolean;
  setShowContent: Function;
}) => {
  const tabbedChangeHandler = (e: string) => {
    if (e === "Main Event") {
      setMainEventActive(true);
    } else {
      setMainEventActive(false);
    }
  };

  return (
    <div ref={eventsRef} className={classes["event-info-container"]}>
      <p className={classes["event-title"]}>New York Climate Week</p>
      <div className={classes["separator"]}>
        <div className={classes["tabbed-component-container"]}>
          <TabComponent
            onChange={tabbedChangeHandler}
            defaultActiveTab={
              mainEventActive ? "Main Event" : "Breakout Sessions"
            }
            tabs={useMemo(() => ["Main Event", "Breakout Sessions"], [])}
          />
        </div>
      </div>
      {mainEventActive ? (
        <MainEvent isMobile={isMobile} />
      ) : (
        <BreakoutSession isMobile={isMobile} />
      )}
    </div>
  );
};

export default EventInfo;
