import React from "react";
import classes from "./App.module.scss";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Layout from "./Pages/Layout/Layout";
import { routes } from "./Pages/Routes/routeConfig";
import styles from "./DesignSystem/_classes.module.scss";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";

const App = () => {
  return (
    <div className={`${classes["main-container"]} ${styles["hide-scrollbar"]}`}>
      <Router>
        <ScrollToTop />

        <Layout>
          <Routes>
            {routes.map((route) => {
              const Component = route.component;
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<Component />}
                ></Route>
              );
            })}
          </Routes>
        </Layout>
      </Router>
    </div>
  );
};

export default App;
