import React from "react";
import classes from "./GetInTouch.module.scss";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

type Props = {
  imgSrc: string;
  content: string | React.ReactNode;
  btnText?: string;
  btnClickHandler?: () => void;
};

function GetInTouch(props: Props) {
  const {
    imgSrc,
    content,
    btnText = "Get in touch",
    btnClickHandler = () => navigate("/contact-us"),
  } = props;

  const navigate = useNavigate();

  return (
    <div className={classes["container"]}>
      <img src={imgSrc} alt="wind-farm" className={classes["wind-farm-img"]} />

      <div className={classes["right-card"]}>
        <div className={classes["details"]}>
          <p>{content}</p>
        </div>

        <Button
          className={classes.btn}
          text={btnText}
          btnClickHandler={btnClickHandler}
          btnStyles={{
            fontWeight: "300",
          }}
        />
      </div>
    </div>
  );
}

export default GetInTouch;
