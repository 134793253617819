import React from "react";
import classes from "./OurSolution.module.scss";
import clemIcon from "../../../assets/icons/clem.svg";
import { ReactComponent as ArrowWithTail } from "../../../assets/icons/arrow_with_tail.svg";
import { SolutionsData } from "../../utils/data/sectorWiseSolutions";

const OurSolution = () => {
  return (
    <div className={classes["solution-container"]}>
      <div className={classes["solution-title"]}>
        Clean Energy Mechanism (CLEM)
      </div>
      <div className={classes["separator"]}></div>
      <div className={classes["solutions-desc"]}>
        <div className={classes["desc-header"]}></div>
      </div>
      <div
        className={classes["img-conatiner"]}
        onClick={() => window.open(SolutionsData?.Clem?.redirectLink, "_blank")}
      >
        <img
          className={classes["header-img"]}
          src={SolutionsData?.Clem?.imgSrc}
          alt="CLEM"
        />
        <img className={classes["title-img"]} src={clemIcon} />
        <ArrowWithTail className={classes.arrow} />
        <p className={classes["image-sub-text"]}>
          Making Net-Zero transitions profitable
        </p>
      </div>
    </div>
  );
};

export default OurSolution;
