import PressRelease from "../../PressRelease/PressRelease";
import SucoFindo from "../../PressRelease/SucoFindo/SucoFindo";
import Aci from "../../PressRelease/Aci/Aci";
import pressReleaseLinks from "./pressReleaseLinks";


const pressReleaseRoutes = [
  {
    path: pressReleaseLinks.main,
    component: PressRelease,
  },
  {
    path: pressReleaseLinks.aci,
    component: Aci,
  },
  {
    path: pressReleaseLinks.sucofindo,
    component: SucoFindo,
  },
];

export default pressReleaseRoutes;
