import { useEffect, useState, useRef } from "react";
import Clem from "./Clem/Clem";
import classes from "./EventDesc.module.scss";
import EventInfo from "./EventInfo/EventInfo";
import AboutUs from "./AboutUs/AboutUs";

const EventDesc = () => {
  const eventsRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  const [mainEventActive, setMainEventActive] = useState<boolean>(true);
  const [showContent, setShowContent] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    // Set the tab name
    document.title =
      "Upcoming Events | Accelerate Your Net-Zero Transition Journey";

    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Join Sustainability Economics at our events to explore strategies and technologies driving net-zero transitions. Network with industry leaders and experts."
      );
    } else {
      // If the meta tag doesn't exist, create it
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Join Sustainability Economics at our events to explore strategies and technologies driving net-zero transitions. Network with industry leaders and experts.";
      document.head.appendChild(meta);
    }

    return () => {
      document.title = "Default Title"; // Restore the default title
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={classes["event-desc-container"]}>
      {/* <div className={classes.header}>
        <img
          className={classes["header-img"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/clem-events-background.png"
          alt="Events"
        />

        <div className={classes.title}>CLEM (Clean Energy Mechanism)</div>
      </div> */}
      <Clem
        eventsRef={eventsRef}
        isMobile={isMobile}
        setMainEventActive={setMainEventActive}
        setShowContent={setShowContent}
      />

      <AboutUs />

      <EventInfo
        eventsRef={eventsRef}
        isMobile={isMobile}
        mainEventActive={mainEventActive}
        setMainEventActive={setMainEventActive}
        showContent={showContent}
        setShowContent={setShowContent}
      />
    </div>
  );
};

export default EventDesc;
