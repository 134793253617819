import React from "react";

import classes from "./Aci.module.scss";

type Detail = {
  title?: string;
  content: string;
  italic?: boolean;
};

const details: Detail[] = [
  {
    content:
      "ACI is an independent non-profit organisation dedicated to establishing voluntary carbon credit standards and a registry. It is committed to accelerate the transition to a carbon-neutraleconomy by producing credible, transparent, and high-quality carbon credits. ACI is also a member of the TRACTION group led by the Monetary Authority of Singapore (MAS), focused on developing transition credits as a complimentary financial instrument for the early retirement of Asia’s coal plants.",
  },
  {
    content:
      "Sustainability Economics is a leading global provider of end-to-end net-zero solutions across multiple sectors. Their expertise is a blend of extensive domain knowledge with state-of-the-art digital platforms and advanced technologies, prioritising lifecycle management and automation by connecting the dots to deliver comprehensive net-zero transition solutions for high-emission sectors.",
  },
  {
    title: "Kasu Venkata Reddy, Co-Founder/CEO of Sustainability Economics",
    content:
      '"We are thrilled to partner with Asia Carbon Institute in our shared mission to accelerate the transition towards clean energy sources in Asia. This collaboration underscores our commitment to driving sustainable solutions and combating climate change."',
    italic: true,
  },
  {
    title: "John Lo, Co-Founder/CEO of Asia Carbon Institute",
    content:
      '"We are pleased that Sustainability Economics has chosen ACI as the standard of choice for evaluating their transition credit methodology. This marks a significant milestone in our efforts to establish transparent standards for carbon credits and accelerate the transition to a carbon-neutral economy."',
    italic: true,
  },
  {
    title: "Transition Credits",
    content:
      "Coal-fired power generation remains a significant contributor to global carbon emissions, particularly in Asia where a concentration of young CFPPs exists, averaging less than 15 years old. To address this pressing issue, Sustainability Economics will work with ACI and submit the methodology that facilitate the transition of CFPPs to clean energy sources. This methodology aims to create high-integrity Transition Credits, a financial instrument designed to accelerate the early retirement of CFPPs in Asia.",
  },
  {
    title: "Market Driven Solution",
    content:
      "Transition Credits, generated through the closure of CFPPs and their replacement with clean energy sources, provide a market-driven solution to enhance the economic viability and scalability of early retirement transactions. These credits adhere to the Core Carbon Principles (CCP) established by the International Carbon Value Chain Management (ICVCM), ensuring credibility and alignment with Sustainable Development Goals (SDGs).",
  },
  {
    title: "Practical and Crafted Methodology",
    content:
      "Sustainability Economics' methodology, rooted in practicality and crafted with input from CFPP owners and renewable energy developers throughout Asia, emphasizes a data-driven approach and the use of digital platforms and state-of-the-art technologies to automate the process of producing Transition Credits from initiation to completion.",
  },
];

function Aci() {
  return (
    <div className={classes.container}>
      <div className={classes["white-card"]}>
        <img
          className={classes["aci-img"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/press-releases/aci.png"
          alt="Asia Carbon Institute"
        />

        <h1 className={classes.heading}>
          Sustainability Economics works with Asia Carbon Institute, of the
          TRACTION group, to accelerate Phase-Out of Coal-Fired Power Plants in
          Asia
        </h1>

        <img
          className={classes["aci-img-mid"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/press-releases/aci.png"
          alt="Asia Carbon Institute"
        />

        <p className={classes.text}>
          <span>Singapore,17 April 2024,</span> Sustainability Economics, a
          leading provider of global net-zero solutions, has announced to choose
          Asia Carbon Institute (ACI) as the standard for evaluating our
          transition credits methodology that aims to expedite the phase-out of
          coal-fired power plants (CFPPs) in the Asia region.
        </p>

        {details.map((detail, index) => (
          <React.Fragment key={index}>
            <p className={classes.title}>{detail.title}</p>

            <p
              className={`${detail.italic ? classes.italic : classes.text} ${
                !detail.title ? classes["apply-margin"] : ""
              }`}
            >
              {detail.content}
            </p>
          </React.Fragment>
        ))}

        <p className={classes.title}>Contact</p>
        <p className={classes.text}>Esther Benschop</p>
        <p className={classes.text}>Communications manager</p>
        <p className={classes.text}>Phone: +65 98851574</p>
        <p className={classes.text}>
          Email:{" "}
          <a href="mailto:esther@sustainabilityeconomics.com">
            esther@sustainabilityeconomics.com
          </a>
        </p>
      </div>
    </div>
  );
}

export default Aci;
