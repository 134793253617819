const Comment = () => {
  return (
    <svg
      width="203"
      height="154"
      viewBox="0 0 203 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 154V67.8095C0 12.073 24.0847 -19.5302 72.2542 -27V5.75237C50.4633 14.3714 39.5678 35.0571 39.5678 67.8095H73.1144V154H0ZM129.886 154V67.8095C129.886 12.073 153.97 -19.5302 202.14 -27V5.75237C180.349 14.3714 169.453 35.0571 169.453 67.8095H203V154H129.886Z"
        fill="#288F90"
        fill-opacity="0.1"
      />
    </svg>
  );
};

export default Comment;
