import advisors from "../../../assets/icons/solutionsWhoWeHelpIcons/advice.png";
import buyers from "../../../assets/icons/solutionsWhoWeHelpIcons/buyer.png";
import factoryOwner from "../../../assets/icons/solutionsWhoWeHelpIcons/factoryOwner.png";
import financier from "../../../assets/icons/solutionsWhoWeHelpIcons/financier.png";

export interface WhoWeHelpItem {
  imgSrc: string;
  title: string;
}

export const whoWeHelpContent: WhoWeHelpItem[] = [
  {
    imgSrc: advisors,
    title: "Advisors",
  },
  {
    imgSrc: buyers,
    title: "Buyers",
  },
  {
    imgSrc: financier,
    title: "Financiers",
  },
  {
    imgSrc: factoryOwner,
    title: "Plant Owners",
  },
];
