import classes from "./Leadership.module.scss";
import kasuImg from "../../../assets/images/founders/ceoImg.png";
import sreenadhaImg from "../../../assets/images/founders/sreeNadha.svg";
import { ReactComponent as FullWave } from "../../../assets/icons/FullWave.svg";
import { ReactComponent as RedirectionArrow } from "../../../assets/icons/arrow_with_tail.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close_icon.svg";
import CeoMasked from "../../../assets/icons/people/ceo_masked.svg";
import ScottMasked from "../../../assets/icons/people/scott_masked.svg";
import { useState } from "react";
import useIsMobile from "../../../Hooks/useIsMobile";

let leaderArr = [
  {
    name: "Kasu Venkata Reddy",
    designation: "Co-Founder & CEO",
    desc: (
      <>
        Seasoned global corporate C-suite executive with over 25 years of
        visionary leadership experience, specializing in strategic planning,
        financial expertise, market expansion, and solving business challenges
        using cutting-edge technologies. <br></br>
        Previously served as Cisco CTO, leading multi-billion dollar business
        initiatives for the APJC market.
      </>
    ),
    profilePic: kasuImg,
    profilePicPopup: kasuImg,
  },
  {
    name: "Nayani Sreenadha Reddy",
    designation: "Co-Founder & Advisor",
    desc: "Seasoned investor and Harvard Alumni with 25+ years of experience in strategy and investing in multiple sectors including IT, Biotech, Real Estate, Education.",
    profilePic: sreenadhaImg,
    profilePicPopup: CeoMasked,
  },
];

const Leadership = () => {
  const isMobile = useIsMobile(650);

  const [leaderPopupVisibility, setLeaderPopupVisiblity] = useState(false);
  const [selectedLeaderIndex, setSelectedLeaderIndex] = useState(0);

  const redirectClickHandler = (receivedIndex: number) => () => {
    setSelectedLeaderIndex(receivedIndex);
    setLeaderPopupVisiblity(true);
  };

  const closeIconClickHandler = () => {
    setLeaderPopupVisiblity(false);
  };

  const NayaniCard = () => (
    <div className={classes["nayani-card-wrapper"]}>
      <div className={classes["nayani-card-container"]}>
        <div className={classes["nayani-description-container"]}>
          <p>{leaderArr[selectedLeaderIndex].desc}</p>
          <div
            className={classes["popup-close-icon-container"]}
            onClick={closeIconClickHandler}
          >
            <CloseIcon />
          </div>
        </div>
        <div className={classes["popup-nayani-image-container"]}>
          <img
            src={leaderArr[selectedLeaderIndex].profilePicPopup}
            alt="Nayani Reddy"
            className={classes["founder-img"]}
          />
          <div className={classes["nayani-details-strip"]}>
            <p className={classes["nayani-name"]}>
              {leaderArr[selectedLeaderIndex].name}
            </p>
            <p className={classes["nayani-designation"]}>
              {leaderArr[selectedLeaderIndex].designation}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className={classes["leadership-container"]}>
        <p
          className={classes["leadership-header"]}
          style={leaderPopupVisibility ? { filter: "blur(5px)" } : {}}
        >
          Our Leadership
        </p>
        <div
          className={classes["separator"]}
          style={leaderPopupVisibility ? { filter: "blur(5px)" } : {}}
        ></div>

        <div className={classes["leadership-content-container"]}>
          <div
            className={classes["leadership-desc-wrapper"]}
            style={{ opacity: leaderPopupVisibility ? 0 : 100 }}
          >
            <p className={classes["leadership-desc"]}>
              Sustainability Economics is guided by exceptional leaders and
              visionaries with decades of expertise in finance, policy,
              technology, and innovation, driving the global transition to clean
              energy and net-zero solutions.
            </p>
          </div>
          <div
            className={classes["leaders-cards-container"]}
            style={{ opacity: leaderPopupVisibility ? 0 : 100 }}
          >
            {leaderArr.map((leaderEle, leaderIndex) => {
              return (
                <div className={classes["leader-card-element-container"]}>
                  <div className={classes["leader-image-container"]}>
                    <img
                      src={leaderEle.profilePic}
                      alt="Kasu Venkata Reddy"
                      className={classes["founder-img"]}
                    />
                    <div
                      className={classes["redirection-container"]}
                      onClick={redirectClickHandler(leaderIndex)}
                    >
                      <RedirectionArrow />
                    </div>
                  </div>
                  <p className={classes["leader-name"]}>{leaderEle.name}</p>
                  <p className={classes["leader-designation"]}>
                    {leaderEle.designation}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        {!leaderPopupVisibility && (
          <div className={classes["fullwave-container"]}>
            <FullWave />
          </div>
        )}

        <div
          className={classes["leader-popup-container"]}
          style={leaderPopupVisibility ? { left: "0" } : { left: "100%" }}
        >
          {isMobile ? (
            <NayaniCard />
          ) : (
            <div className={classes["leader-card-wrapper"]}>
              <div className={classes["leader-card-container"]}>
                <div className={classes["popup-leader-image-container"]}>
                  <img
                    src={leaderArr[selectedLeaderIndex].profilePicPopup}
                    alt="Kasu Venkata Reddy"
                    className={classes["founder-img"]}
                  />
                  <div className={classes["leader-details-strip"]}>
                    <p className={classes["leader-name"]}>
                      {leaderArr[selectedLeaderIndex].name}
                    </p>
                    <p className={classes["leader-designation"]}>
                      {leaderArr[selectedLeaderIndex].designation}
                    </p>
                  </div>
                </div>
                <div className={classes["leader-description-container"]}>
                  <p>{leaderArr[selectedLeaderIndex].desc}</p>
                </div>
                <div
                  className={classes["popup-close-icon-container"]}
                  onClick={closeIconClickHandler}
                >
                  <CloseIcon />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={classes["scott-card-container"]}>
        <div className={classes["scott-card-header"]}>
          <p className={classes["scott-heading"]}>Our Advisory</p>
          <div className={classes["separator"]}></div>
        </div>
        <div className={classes["scott-card-content"]}>
          <div className={classes["scott-image-container"]}>
            <img
              src={ScottMasked}
              alt="Scott Eaton"
              className={classes["founder-img"]}
            />
            <div className={classes["scott-details-strip"]}>
              <p className={classes["scott-name"]}>Scott Eaton</p>
              <p className={classes["scott-designation"]}>Advisor</p>
            </div>
          </div>
          <div className={classes["scott-desc-container"]}>
            <p>
              Scott has held a number of senior leadership roles in financial
              technology and capital markets, having previously been at capital
              markets fintech firm Nivaura where he served as CEO.  <br></br>
              <br></br>Prior to Nivaura, he was CEO of Algomi from 2018 until
              its sale to BGC in 2020.  From 2015 – 2018, Scott was COO at fixed
              income trading platform provider MarketAxess.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leadership;
