export interface SectorWiseSolution {
  imgSrc: string;
  title: string;
  redirectLink?: string;
}

export const sectorWiseSolutionsData: SectorWiseSolution[] = [
  {
    imgSrc:
      "https://se-website-image.s3.ap-south-1.amazonaws.com/power-plant-with-smoke-coming-out-it-power-plant-background.jpg",
    title: "Energy",
    redirectLink: process.env.REACT_APP_CLEM_URL,
  },
  {
    imgSrc:
      "https://se-website-image.s3.ap-south-1.amazonaws.com/apartment-office-tall-building-construction-top-view-tower-crane-city-landscape-stretching-horizon-drone-aerial-photography.jpg",
    title: "Real Estate",
  },
  {
    imgSrc:
      "https://se-website-image.s3.ap-south-1.amazonaws.com/environmental-pollution-factory-exterior.jpg",
    title: "Heavy Industries",
  },
];

export const SolutionsData: any = {
  Clem: {
    imgSrc:
      "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/Group+1410121427.jpg",
    title: "Clem",
    redirectLink: process.env.REACT_APP_CLEM_URL,
  },
  CLEMEnablement: {
    imgSrc:
      "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/Group+1410121423.jpg",
    title: "CLEM Enablement",
    redirectLink: "https://clem.sustainabilityeconomics.com/enablement",
  },
  CLEMTransition: {
    imgSrc:
      "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/Group+1410121424.jpg",
    title: "CLEM Transition",
    redirectLink: "https://clem.sustainabilityeconomics.com/transitions",
  },
  CLEMEcosystem: {
    imgSrc:
      "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/Group+1410121422.svg",
    title: "CLEM Transition",
  },
};
