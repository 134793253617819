import { useRef, useEffect } from "react";
import classes from "./EmbracingDiversityAndInnovation.module.scss";

const images = [
  "https://se-website-image.s3.ap-south-1.amazonaws.com/se-photos/IMG_0850.jpg",
  "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/whites1.jpg",
  "https://se-website-image.s3.ap-south-1.amazonaws.com/se-photos/IMG_0824-min.jpg",
  "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/whites2.jpg",
  "https://se-website-image.s3.ap-south-1.amazonaws.com/se-photos/IMG_0781-Enhanced-SR-min.jpg",
  "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/whites3.jpg",
  "https://se-website-image.s3.ap-south-1.amazonaws.com/se-photos/IMG_0854-min.jpg",
  "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/whites4.jpg",
  "https://se-website-image.s3.ap-south-1.amazonaws.com/se-photos/IMG_0797-Enhanced-SR-min.jpg",
  "https://se-website-image.s3.ap-south-1.amazonaws.com/se-photos/IMG_0862-min.jpg",
  "https://se-website-image.s3.ap-south-1.amazonaws.com/se-photos/IMG_0897-min.jpg",
  "https://se-website-image.s3.ap-south-1.amazonaws.com/se-photos/IMG_7602-Enhanced-SR-min.jpg",
  "https://se-website-image.s3.ap-south-1.amazonaws.com/se-photos/IMG_0925-Enhanced-SR-min.jpg",
];

function EmbracingDiversityAndInnovation() {
  const imageSliderRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = (isImageSlider: boolean) => {
      if (imageSliderRef.current && sliderRef.current) {
        const imageSlider = imageSliderRef.current;
        const slider = sliderRef.current;

        const scrollPercent =
          (imageSlider.scrollLeft /
            (imageSlider.scrollWidth - imageSlider.clientWidth)) *
          100;

        if (
          Math.abs(
            slider.scrollLeft -
              (scrollPercent / 100) * (slider.scrollWidth - slider.clientWidth)
          ) > 1
        ) {
          slider.scrollLeft =
            (scrollPercent / 100) * (slider.scrollWidth - slider.clientWidth);
        }
      }
    };

    const handleScroll1 = () => {
      if (imageSliderRef.current && sliderRef.current) {
        const slider = sliderRef.current;
        const imageSlider = imageSliderRef.current;

        const scrollPercent =
          (slider.scrollLeft / (slider.scrollWidth - slider.clientWidth)) * 100;

        if (
          Math.abs(
            imageSlider.scrollLeft -
              (scrollPercent / 100) *
                (imageSlider.scrollWidth - imageSlider.clientWidth)
          ) > 1
        ) {
          imageSlider.scrollLeft =
            (scrollPercent / 100) *
            (imageSlider.scrollWidth - imageSlider.clientWidth);
        }
      }
    };

    const imageSlider: any = imageSliderRef.current;
    const slider: any = sliderRef.current;

    if (imageSlider) {
      imageSlider.addEventListener("scroll", handleScroll);
    }

    if (slider) {
      slider.addEventListener("scroll", handleScroll1);
    }

    return () => {
      if (imageSlider) {
        imageSlider.removeEventListener("scroll", handleScroll);
      }

      if (slider) {
        slider.removeEventListener("scroll", handleScroll1);
      }
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.title}>Embracing Diversity & Innovation</div>

      <p className={classes.text}>
        Join our team to be part of a dynamic environment where innovation
        drives the transition to a sustainable future.
      </p>

      <div className={classes["image-slider"]} ref={imageSliderRef}>
        {images.map((imgSrc, i) => (
          <img
            key={i}
            src={imgSrc}
            alt={i.toString()}
            className={classes.image}
          />
        ))}
      </div>

      <div className={classes.slider} ref={sliderRef}>
        {images.map((_, i) => (
          <div key={i} className={classes.card} />
        ))}
      </div>
    </div>
  );
}

export default EmbracingDiversityAndInnovation;
