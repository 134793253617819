import React from "react";
import classes from "./ClemVision.module.scss";
import { ReactComponent as ArrowWithTail } from "../../../assets/icons/arrow_with_tail.svg";
import { SolutionsData } from "../../utils/data/sectorWiseSolutions";

const ClemVision = () => {
  return (
    <div className={classes["clem-vision"]}>
      <div className={classes["vision-title"]}>CLEM Vision</div>
      <div className={classes["separator"]}></div>
      <div className={classes["vision-desc"]}>
        CLEM's objective is to prioritize profitability while enabling and
        accelerating clean energy technologies.
      </div>
      <div className={classes["clem-submodules"]}>
        <div
          className={classes["clem-submodule"]}
          onClick={() =>
            window.open(SolutionsData?.CLEMEnablement?.redirectLink, "_blank")
          }
        >
          <img
            className={classes["header-img"]}
            src={SolutionsData?.CLEMEnablement?.imgSrc}
            alt="Clem Enablement"
          />
          <div className={classes.title}>CLEM Enablement</div>
          <ArrowWithTail className={classes.arrow} />
        </div>
        <div
          className={classes["clem-submodule"]}
          onClick={() =>
            window.open(SolutionsData?.CLEMTransition?.redirectLink, "_blank")
          }
        >
          <img
            className={classes["header-img"]}
            src={SolutionsData?.CLEMTransition?.imgSrc}
            alt="Clem Transition"
          />
          <div className={classes.title}>CLEM Transition</div>
          <ArrowWithTail className={classes.arrow} />
        </div>
      </div>
    </div>
  );
};

export default ClemVision;
