import HomePage from "./HomePage/HomePage";
import OurVision from "./OurVision/OurVision";
import classes from "./LandingPage.module.scss";
import VideoSection from "./VideoSection/VideoSection";
import LinksSection from "./LinksSection/LinksSection";
import SeBackgroundHoverCards from "../../Components/SeBackgroundHoverCards/SeBackgroundHoverCards";
import WhoWeWorkWith from "./WhoWeWorkWith/WhoWeWorkWith";
import OurAssociation from "./OurAssociation/OurAssociation";
import OrnamentalImage from "./OrnamentalImage/OrnamentalImage";
import OurStrategy from "./OurStrategy/OurStrategy";
import GetInTouch from "../../Components/GetInTouch/GetInTouch";
import useIsMobile from "../../Hooks/useIsMobile";
import OurAssociationMobile from "./OurAssociation/Mobile/OurAssociation";
import GlobalPresence from "./GlobalPresence/GlobalPresence";

const LandingPage = () => {
  const isMobile = useIsMobile();

  return (
    <div className={classes["landingpage-container"]}>
      <HomePage />
      <OurVision />
      <VideoSection />
      <GlobalPresence />
      {!isMobile && <LinksSection />}
      <WhoWeWorkWith />
      <SeBackgroundHoverCards
        title="Who We Work With"
        subTitle="We serve businesses, buyers, and lenders, providing tailored solutions to simplify and accelerate their path to achieving Net-Zero through effective transition strategies."
        cardsContainerHeight={490}
        cards={[
          {
            title: "Business Owners",
            desc: "We assist business owners in shifting to low-carbon practices by creating a detailed and comprehensive transition analysis plan. This plan helps businesses achieve their Net-Zero targets effectively.",
          },
          {
            title: "Lenders",
            desc: "Our goal is to bridge the economic gap between lenders and buyers. By providing a clear transition analysis plan, we make it easier for lenders to finance towards a Net-Zero economy.",
          },
          {
            title: "Buyers",
            desc: "By providing a clear transition analysis plan and bridging the economic gap between stakeholders, we aim to streamline the resource access process for buyers.",
          },
          {
            title: "Advisors",
            desc: "We offer insights and tools to navigate clean energy transitions, providing end-to-end solutions from identifying opportunities to executing profitable strategies, making us a key resource for those advising on sustainable investments and development.",
          },
        ]}
      />
      {/* {isMobile ? <OurAssociationMobile /> : <OurAssociation />} */}
      <OurAssociation />
      {/* <OrnamentalImage /> */}
      <OurStrategy />
      <GetInTouch
        imgSrc="https://se-website-image.s3.ap-south-1.amazonaws.com/wind-farm.jpg"
        content={
          <>
            Ready to <span>start</span> your <span>journey</span> towards a
            <span> sustainable, clean energy</span> future?
          </>
        }
      />
    </div>
  );
};

export default LandingPage;
