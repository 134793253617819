import { useState } from "react";
import Card from "../Card/Card";
import classes from "./SeBackgroundHoverCards.module.scss";
import { ReactComponent as TopWave } from "../../assets/icons/top_wave.svg";
import { ReactComponent as DownWave } from "../../assets/icons/down_wave.svg";
import useIsMobile from "../../Hooks/useIsMobile";
import SeBackgroundHoverCardsMobile from "../SeBackgroungHoverCardsMobile/SeBackgroungHoverCardsMobile";

type Props = {
  title: string;
  subTitle: string;
  cards: { title: string; desc: string }[];
  cardsContainerHeight?: number;
};

const SeBackgroundHoverCards = (props: Props) => {
  const isMobile = useIsMobile(769);
  const isTab = useIsMobile(1000);
  const { title, subTitle, cards, cardsContainerHeight = 320 } = props;

  const [hoveredIndex, setHoveredIndex] = useState<number>(0);

  const mouseEnterHandler = (receivedIndex: number) => () => {
    setHoveredIndex(receivedIndex);
  };

  if (isMobile) {
    return (
      <SeBackgroundHoverCardsMobile
        title={title}
        subTitle={subTitle}
        cards={cards}
      />
    );
  }

  return (
    <div className={classes["cater-to-container"]}>
      <div className={classes["ctc-title"]}>{title}</div>
      <div className={classes["ctc-separator"]} />
      <div className={classes["ctc-desc"]}>
        <p>{subTitle}</p>
      </div>
      <div
        className={classes["ctc-cards-container"]}
        style={{ height: `${cardsContainerHeight}px` }}
      >
        {cards.map((el, index: number) => (
          <Card
            key={index}
            customClass={
              hoveredIndex === index
                ? `${classes["ctc-card"]} ${classes["ctc-card-expanded"]}`
                : `${classes["ctc-card"]} ${classes["ctc-card-normal"]}`
            }
            extraProps={{
              onMouseEnter: mouseEnterHandler(index),
            }}
          >
            <div className={classes["title-separator-container"]}>
              <div className={classes["ctc-card-title"]}>{el.title}</div>
              {hoveredIndex === index && (
                <div className={classes["ctc-card-separator"]} />
              )}
            </div>
            <div className={classes["ctc-card-desc"]}>
              {hoveredIndex === index && <p>{el.desc}</p>}
            </div>
          </Card>
        ))}
      </div>
      {!isTab && (
        <>
          <div className={classes["top-wave-container"]}>
            <TopWave />
          </div>
          <div className={classes["bottom-wave-container"]}>
            <DownWave />
          </div>
        </>
      )}
    </div>
  );
};

export default SeBackgroundHoverCards;
