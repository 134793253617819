import React from "react";
import classes from "./SucoFindo.module.scss";

import clemStrategy from "../../../assets/icons/clemStrategy.svg";
import clemKeyOutcomes from "../../../constants/clemKeyOutcomes";

type Detail = {
  title?: string;
  content: string;
  italic?: boolean;
};

const details: Detail[] = [
  {
    content:
      "Established in 1956, PT SUCOFINDO is a joint venture company between the Government of the Republic of Indonesia and SGS, Geneve, Switzerland. As the first inspection in Indonesia, the company currently works closely with the Indonesian policymakers, CFPP asset owners, power purchasers and financial institutions to accelerate clean energy transitions across Indonesia.",
  },
  {
    content:
      "Sustainability Economics, an international entity headquartered in Singapore and US, with a big development center in Bangalore providing end-to-end net-zero solutions across high emitting sectors for global businesses. Sustainability Economics brings CLEM (Clean Energy Mechanism), a first-of-its-kind end-to-end solution to accelerate clean energy transitions globally.",
  },
  {
    title: "Kasu Venkata Reddy, Co-Founder/CEO of Sustainability Economics ",
    content:
      '"We are excited to partner with PT SUCOFINDO in our shared mission to accelerate the clean energy transition across Indonesia. This collaboration highlights our strong commitment towards achieving net zero goals globally."',
    italic: true,
  },
  {
    title: "Budi Utomo, Director of Industrial Services of SUCOFINDO",
    content:
      '"Partnership with Sustainability Economics will equip our competence and end-to-end service with an international presence that bring values to the Independence Power Producers’ agenda of energy transition towards net zero emissions with the principles of just and affordability."',
    italic: true,
  },
  {
    title: "CLEM – Practical & Crafted Approach",
    content:
      "Coal-fired power generation remains a significant contributor to global carbon emissions, particularly in Asia, where a concentration of young CFPPs exists, averaging less than 15 years old. To address this pressing issue, CLEM, a first-of-its-kind clean energy transition tailor-made solution, takes a practical data-driven approach working with CFPP asset owners, power purchasers and policymakers by connecting the dots to automate, simplify and scale the clean energy transitions.",
  },
  {
    title: "CLEM VISION: MAKING ENERGY TRANSITIONS PROFITABLE",
    content:
      "CLEM’s vision is to make energy transition not just practical but profitable for CFPP asset owners and financiers. Leveraging a cutting-edge, data-driven approach, CLEM’s tailor-made solutions provide the most effective and strategic clean energy transition planning while ensuring profitability with a make-it-happen approach",
  },
];

function SucoFindo() {
  return (
    <div className={classes.container}>
      <div className={classes["white-card"]}>
        <img
          className={classes["sucofindo-img"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/press-releases/sucofindo.png"
          alt="Asia Carbon Institute"
        />

        <h1 className={classes.heading}>
          Sustainability Economics partners with PT SUCOFINDO, a state-owned
          company of Indonesia, to accelerate the transition of coal-fired power
          plants to clean energy sources in Indonesia
        </h1>

        <img
          className={classes["sucofindo-img-mid"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/press-releases/sucofindo.png"
          alt="Asia Carbon Institute"
        />

        <p className={classes.text}>
          <span>Singapore,26 July 2024,</span> Sustainability Economics, an
          international entity, providing end-to-end net-zero solutions for high
          emitting sectors brings in first-of-its-kind CLEM (Clean Energy
          Mechanism) an end-to-end solution for CFPP transitions with a vision
          of making transition profitable, has announced to partner with PT
          SUCOFINDO, a state owned company supporting government policies and
          capacity building to accelerate CFPPs transitions across Indonesia.
        </p>

        {details.map((detail, index) => (
          <React.Fragment key={index}>
            <p className={classes.title}>{detail.title}</p>

            <p
              className={`${detail.italic ? classes.italic : classes.text} ${
                !detail.title ? classes["apply-margin"] : ""
              }`}
            >
              {detail.content}
            </p>
          </React.Fragment>
        ))}

        <div className={classes["strategy-img-container"]}>
          <img
            className={classes["strategy-img"]}
            src={clemStrategy}
            alt="CLEM strategy and solution"
          />
        </div>

        <p className={classes.title}>Key outcomes:</p>

        {clemKeyOutcomes.map((outcome, index) => (
          <p className={`${classes.text} ${classes.outcome}`} key={index}>
            <span>{outcome.title}</span> - {outcome.description}
          </p>
        ))}

        <p className={classes.title}>Contact</p>
        <p className={classes.text}>Suzanne Linville</p>
        <p className={classes.text}>Communications manager</p>
        <p className={classes.text}>Phone: +720.708.9806</p>
        <p className={classes.text}>
          Email:{" "}
          <a href="mailto:suzanne@sustainabilityeconomics.com">
            suzanne@sustainabilityeconomics.com
          </a>
        </p>
      </div>
    </div>
  );
}

export default SucoFindo;
