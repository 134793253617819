import { useEffect } from "react";
import classes from "./Header.module.scss";
import { ReactComponent as SeLogo } from "../../../assets/icons/headerSeLogo.svg";
import { ReactComponent as BreadCrumb } from "../../../assets/icons/bread_crumb.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close_icon.svg";
import Button from "../../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../Hooks/useIsMobile";
import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";

type Path = {
  title: string;
  path?: string;
  toCLEM?: boolean;
  pathName?: string;
};

const paths: Path[] = [
  {
    title: "About Us",
    path: "/about-us",
    pathName: "/about-us",
  },
  {
    title: "Solutions",
    path: "/solutions",
    pathName: "/solutions",
  },
  {
    title: "Events",
    path: "/resources/events-overview",
    pathName: "/events-overview",
  },
  // {
  //   title: "Blogs",
  //   path: "/",
  // },
  {
    title: "News",
    path: "/resources/press-release",
    pathName: "/press-release",
  },
  {
    title: "CLEM",
    path: "https://clem.sustainabilityeconomics.com/",
    pathName: "",
    toCLEM: true,
  },
  // {
  //   title: "CLEM",
  //   toCLEM: true,
  // },
  // {
  //   title: "Resources",
  //   path: "/resources",
  // },
];

const Header = () => {
  const resourcesRef = useRef<any>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const pathSegments = location.pathname.split("/").filter(Boolean);

  const isMobile = useIsMobile(900);
  const [headerPopUpVisibility, setHeaderPopupVisibility] = useState(false);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [
    solutionsDropdownVisibilityMobile,
    setSolutionsDropdownVisibilityMobile,
  ] = useState<boolean>(false);
  const [clemDropdownVisibilityMobile, setClemDropdownVisibilityMobile] =
    useState<boolean>(false);
  const [bannerVisibility, setBannerVisibility] = useState(true);

  const gitClickHandler = () => {
    window.open("https://wtk94jvv-3000.inc1.devtunnels.ms/", "_blank");
  };

  const navLinkClickHandler = (link: any, fromPopup?: boolean) => {
    navigate(link);

    if (fromPopup) setHeaderPopupVisibility(false);
  };

  const breadCrumbClickHandler = () => {
    setHeaderPopupVisibility((prevState) => !prevState);
  };

  const crossClickHandler = () => {
    setHeaderPopupVisibility(false);
  };

  const clemClickHandler = () => {
    window.open(process.env.REACT_APP_CLEM_URL, "_blank");
  };

  const linkClickHandler = (
    path: any,
    toCLEM: boolean = false,
    fromPopup: boolean = false
  ) => {
    if (toCLEM) {
      clemClickHandler();

      if (fromPopup) setHeaderPopupVisibility(false);
      return;
    }

    navLinkClickHandler(path, fromPopup);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;

      if (
        resourcesRef &&
        resourcesRef.current &&
        !resourcesRef.current.contains(target)
      ) {
        setDropdownVisibility(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={classes["header-container"]}>
        {!isMobile ? (
          <>
            <div
              className={classes["se-logo-container"]}
              onClick={() => navLinkClickHandler("/")}
            >
              <SeLogo />
            </div>
            <div className={classes["header-links-container"]}>
              {paths.map((path) => (
                <p
                  key={path.title}
                  onClick={() => {
                    linkClickHandler(path.path, path.toCLEM);
                  }}
                  className={
                    `/${pathSegments[0]}` === path.pathName ||
                    `/${pathSegments[1]}` === path.pathName
                      ? classes["active"]
                      : ""
                  }
                  ref={path.title === "Solutions" ? resourcesRef : null}
                  // onMouseEnter={() => {
                  //   if (path.title === "Solutions") {
                  //     setDropdownVisibility((prevState) => !prevState);
                  //   }
                  // }}
                  // onMouseLeave={() => {
                  //   if (path.title === "Solutions") {
                  //     setDropdownVisibility((prevState) => !prevState);
                  //   }
                  // }}
                >
                  {path.title === "Solutions" ? (
                    <>
                      Solutions
                      {/* <FilledArrow
                        style={{
                          transition: "transform 0.4s ease",
                        }}
                        arrowType={dropdownVisibility ? "up" : "down"}
                      /> */}
                      {/* <Dropdown
                        dropdownVisibility={dropdownVisibility}
                        parentPath="/resources"
                        containerClass={classes["dropdown-container"]}
                      /> */}
                    </>
                  ) : (
                    path.title
                  )}
                </p>
              ))}

              {/* <p onClick={navLinkClickHandler("/about-us")}>About Us</p>
                <p onClick={navLinkClickHandler("/solutions")}>Solutions</p>
                <p onClick={clemClickHandler}>CLEM</p>

                <p
                  onClick={() =>
                    setDropdownVisibility((prevState) => !prevState)
                  }
                ></p> */}

              {/* <p onClick={navLinkClickHandler("/events-overview")}>Events</p>
                <p
                  onClick={() =>
                    window.open(
                      "https://sustainabilityeconomicsnews.com/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  Media
                </p> */}
            </div>

            <div className={classes["header-right-container"]}>
              <div className={classes["header-btns-container"]}>
                <div className={classes["sen-btn-container"]}>
                  <Button
                    text="SE News"
                    btnClickHandler={() =>
                      window.open(
                        "https://sustainabilityeconomicsnews.com/",
                        "_blank"
                      )
                    }
                    btnStyles={{
                      backgroundColor: "#272727",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  ></Button>
                </div>
                <div className={classes["git-btn-container"]}>
                  <Button
                    text="Get in touch"
                    btnClickHandler={() => navLinkClickHandler("/contact-us")}
                    btnStyles={{
                      backgroundColor: "#288f90",
                      fontSize: "14px",
                      fontWeight: 300,
                    }}
                  ></Button>
                </div>
                {/* <div className={classes["clem-btn-container"]}>
                <Button
                  text="CLEM"
                  btnClickHandler={clemClickHandler}
                  btnStyles={{
                    backgroundColor: "#1868db",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                  shiny={true}
                ></Button>
              </div> */}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={classes["se-logo-container"]}
              onClick={() => navLinkClickHandler("/")}
            >
              <SeLogo />
            </div>
            <div
              className={classes["bread-crumb-container"]}
              onClick={breadCrumbClickHandler}
            >
              {headerPopUpVisibility ? (
                <div className={classes["close-icon-container"]}>
                  <CloseIcon />
                </div>
              ) : (
                <BreadCrumb />
              )}
            </div>

            {/* <div className={classes["clem-btn-container"]}>
              <Button
                text="CLEM"
                btnClickHandler={clemClickHandler}
                btnStyles={{
                  backgroundColor: "#1868db",
                  fontSize: "7.53px",
                  fontWeight: 600,
                  height: "23.97px",
                  minWidth: "45.55px",
                  borderRadius: "3.23px",
                  letterSpacing: "0.5px",
                }}
                shiny={true}
              ></Button>
            </div> */}
            {headerPopUpVisibility && (
              <div
                className={
                  headerPopUpVisibility
                    ? `${classes["header-popup-container"]} ${classes["visible"]}`
                    : `${classes["header-popup-container"]} ${classes["hide"]}`
                }
              >
                {/* <div className={classes["logo-exit-container"]}>
                  <div
                    className={classes["se-logo-container-popup"]}
                    onClick={() => navLinkClickHandler("/", true)}
                  >
                    <SeLogoText />
                  </div>
                  <div
                    className={classes["cross-logo-container"]}
                    onClick={crossClickHandler}
                  >
                    <CrossIcon />
                  </div>
                </div> */}
                <div className={classes["hp-links-container"]}>
                  {paths.map((path, index) => (
                    <>
                      <div
                        key={path.title}
                        className={`${classes["hp-link-element"]} ${
                          `/${pathSegments[0]}` === path.path
                            ? classes["active"]
                            : ""
                        }`}
                        onClick={() => {
                          if (path.title !== "Solutions") {
                            linkClickHandler(path.path, path.toCLEM, true);
                          }
                        }}
                      >
                        {path.title === "Solutions" ? (
                          <>
                            <div
                              className={classes["solutions-element"]}
                              onClick={() => {
                                navLinkClickHandler("/solutions");
                                // setSolutionsDropdownVisibilityMobile(
                                //   (prevState) => !prevState
                                // );
                              }}
                            >
                              Solutions
                              {/* <FilledArrow
                                style={{
                                  transition: "transform 0.4s ease",
                                  marginLeft: "4px",
                                }}
                                width={12}
                                height={8}
                                arrowType={
                                  solutionsDropdownVisibilityMobile
                                    ? "up"
                                    : "down"
                                }
                              /> */}
                            </div>
                            {solutionsDropdownVisibilityMobile && (
                              <div
                                className={
                                  classes["solutions-dropdown-container"]
                                }
                              >
                                <div
                                  onClick={() => {
                                    navLinkClickHandler("/solutions");
                                  }}
                                >
                                  SE Solutions
                                </div>
                                <div className={classes["separator"]}></div>
                                <div
                                  className={classes["clem-dropdown-wrapper"]}
                                >
                                  <div
                                    className={classes["clem-element"]}
                                    onClick={() => {
                                      // setClemDropdownVisibilityMobile(
                                      //   (prevState) => !prevState
                                      // );
                                      clemClickHandler();
                                    }}
                                  >
                                    <p>CLEM</p>
                                    {/* <FilledArrow
                                      style={{
                                        transition: "transform 0.4s ease",
                                        marginLeft: "4px",
                                      }}
                                      width={12}
                                      height={8}
                                      arrowType={
                                        clemDropdownVisibilityMobile
                                          ? "up"
                                          : "down"
                                      }
                                    /> */}
                                  </div>
                                  {clemDropdownVisibilityMobile && (
                                    <div
                                      className={
                                        classes["clem-dropdown-container"]
                                      }
                                    >
                                      <p>CLEM Transitions</p>
                                      <div
                                        className={classes["separator"]}
                                      ></div>
                                      <p
                                        className={
                                          classes["clem-dropdown-ce-element"]
                                        }
                                      >
                                        CLEM Enablement
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          path.title
                        )}
                      </div>

                      {index !== paths.length - 1 ? (
                        <div className={classes["separator"]}></div>
                      ) : null}
                    </>
                  ))}
                  <div className={classes["separator"]}></div>
                  <Button
                    text="SE News"
                    btnClickHandler={() =>
                      window.open(
                        "https://sustainabilityeconomicsnews.com/",
                        "_blank"
                      )
                    }
                    btnStyles={{
                      backgroundColor: "#272727",
                      fontSize: "10px",
                      fontWeight: 600,
                      height: "30px",
                      minWidth: "70px",
                      borderRadius: "3.23px",
                      letterSpacing: "0.5px",
                      marginTop: "1.5rem",
                    }}
                    // shiny={true}
                  ></Button>
                </div>
                {/* <div className={classes["clem-btn-container"]}>
                  <Button
                    text="CLEM"
                    btnClickHandler={clemClickHandler}
                    btnStyles={{
                      backgroundColor: "#1868db",
                      fontSize: "10.11px",
                      fontWeight: 400,
                      height: "26.72px",
                      width: "65px",
                      borderRadius: "3.23px",
                    }}
                    shiny={true}
                  ></Button>
                </div> */}
                <div className={classes["get-in-touch-container"]}>
                  <Button
                    text="Get in touch"
                    btnClickHandler={() =>
                      navLinkClickHandler("/contact-us", true)
                    }
                    btnStyles={{
                      backgroundColor: "#288f90",
                      fontSize: "14px",
                      fontWeight: 300,
                      width: "100%",
                      padding: "0.5rem 0rem",
                    }}
                  ></Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {/* {bannerVisibility &&
        location.pathname !== "/events" &&
        location.pathname !== "/events-overview" && (
          <div className={classes["banner-wrapper"]}>
            <Banner setBannerVisibility={setBannerVisibility} />
          </div>
        )} */}
    </>
  );
};

export default Header;
