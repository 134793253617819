import classes from "./OurStrategyMobile.module.scss";
import strategy from "../../assets/icons/strategy.svg";
import { useState } from "react";
import strategyArr from "../Solutions/OurStrategy/strategyContent";

const OurStrategyMobile = () => {
  const [activeBoxNumber, setActiveBoxNumber] = useState(1);

  const leftClickHandler = () => {
    setActiveBoxNumber((prevState) =>
      prevState - 1 < 1 ? strategyArr.length : prevState - 1
    );
  };

  const rightClickHandler = () => {
    setActiveBoxNumber((prevState) =>
      prevState + 1 > strategyArr.length ? 1 : prevState + 1
    );
  };

  return (
    <div className={classes["strategy-container"]}>
      <div className={classes["strategy-title"]}>Our Strategy</div>
      <div className={classes["separator"]} />
      <div className={classes["se-strategy-img-container"]}>
        <img
          alt="seStrategy.png"
          src={strategy}
          className={classes["strategy-img"]}
        />
      </div>
      <div className={classes["arrow-card-container"]}>
        <div className={classes["cards-scroll-container"]}>
          {strategyArr.map((item, index) => (
            <div key={index} className={classes["card-container"]}>
              <div className={classes["card-body"]}>
                <p className={classes["card-title"]}>{item.title}</p>
                <p className={classes["card-desc"]}>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurStrategyMobile;
